import { useIsFetching, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate, useRoutes, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from './components/loading-spinner'
import { defaultRoute } from './routes/route'
import { Footer } from './components/footer'
import {
  Toolbar,
  Typography,
  Box,
  experimentalStyled,
  AppBar,
  Button,
  IconButton,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  useTheme
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  Home,
  Menu as MenuIcon,
  SearchOutlined
} from '@mui/icons-material'
import { fetchMenuPoints } from './lib/requests/fetch-menu-points'
import useSearchParamsHelper from './hooks/use-search-params'
const headerImage = require('./images/header_line.png')
const backgroundStructure = require('./images/background-structure.png')
const siteLogo = require('./images/site_logo.png')
const logoLucija = require('./images/logo_lucija.png')
const logoSst = require('./images/sst_logo.png')

const drawerWidth = 320

// eslint-disable-next-line @typescript-eslint/no-shadow
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

// eslint-disable-next-line @typescript-eslint/no-shadow
const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: 2
}))

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: `${drawerWidth}px`
  })
}))

function App() {
  const theme = useTheme()
  const content = useRoutes(defaultRoute)
  const isFetching = useIsFetching()
  const navigate = useNavigate()
  const menuPoints = useQuery(['menuPoints'], () => fetchMenuPoints())
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [params] = useSearchParams()
  const [menuOpened, setMenuOpened] = useState(false)
  const isDesktop = window.innerWidth >= 1024
  const {
    activeTopic,
    activeCategory,
    activeSubCategory,
    setActiveTopic,
    setActiveCategory,
    setActiveSubCategory,
    clearParams
  } = useSearchParamsHelper()

  const getMenuPoints = (topicId: string | null, categoryId: string | null) => {
    if (menuPoints?.isFetched) {
      if (!topicId) return menuPoints.data?.data.topics || []

      if (!categoryId)
        return (
          menuPoints.data?.data?.topics?.find((d) => parseInt(d.id) === parseInt(topicId))
            ?.categories || []
        )

      if (categoryId)
        return (
          menuPoints.data?.data?.topics
            ?.find((d) => parseInt(d.id) === parseInt(topicId))
            ?.categories.find((category) => parseInt(category.id) === parseInt(categoryId))
            ?.subCategories || []
        )
    }
    return []
  }

  useEffect(() => {
    params.forEach((value, key) => {
      if (key === 'tema') {
        const foundTopic = menuPoints.data?.data.topics.find(
          (d) => parseInt(d.id) === parseInt(value)
        )
        if (foundTopic) {
          setActiveTopic(value, foundTopic.title, foundTopic.color)
        }
      } else if (key === 'category') {
        if (params.get('tema')) {
          const foundCategory = getMenuPoints(params.get('tema'), null).find(
            (d) => parseInt(d.id) === parseInt(value)
          )

          if (foundCategory) {
            setActiveCategory(value, foundCategory.title)
          }
        }
      } else if (key === 'subcategory') {
        if (params.get('tema') && params.get('category')) {
          const foundSubCategory = getMenuPoints(params.get('tema'), params.get('category')).find(
            (d) => parseInt(d.id) === parseInt(value)
          )
          if (foundSubCategory) setActiveSubCategory(value, foundSubCategory.title)
        }
      }
    })
  }, [menuPoints.isFetched])

  return (
    <div
      style={{
        background: activeTopic ? activeTopic.color : theme.palette.background.default,
        minHeight: 'calc(100vh - 64px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <img
        style={{
          opacity: 0.1,
          position: 'fixed',
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
          pointerEvents: 'none'
        }}
        src={backgroundStructure}
      />
      <DashboardNavbarRoot>
        <Toolbar
          sx={{
            height: 64
          }}
        >
          <Box>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={() => setDrawerOpened(true)}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='home'
              onClick={() => {
                clearParams()
                window.scrollTo({ top: 0, behavior: 'smooth' })
                navigate('/')
              }}
            >
              <Home />
            </IconButton>
            {menuPoints?.data?.data?.topics && (
              <Drawer
                sx={{
                  width: drawerOpened ? drawerWidth : 0,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box'
                  }
                }}
                variant='persistent'
                anchor='left'
                open={drawerOpened}
              >
                <DrawerHeader>
                  <IconButton onClick={() => setDrawerOpened(!drawerOpened)}>
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  {menuPoints.data.data.topics
                    .sort((a, b) => a.sortingKey - b.sortingKey)
                    .map((topic) => (
                      <ListItem
                        key={topic.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <ListItemButton
                          sx={{
                            width: '100%',
                            backgroundColor:
                              activeTopic?.id === topic.id ? 'rgba(0,0,0,0.1)' : 'inherit'
                          }}
                          onClick={() => {
                            setMenuOpened(true)
                            setActiveTopic(topic.id, topic.title, topic.color)
                          }}
                        >
                          <ListItemText primary={topic.title} />
                          {activeTopic?.id === topic.id && menuOpened && (
                            <ExpandLess
                              onClick={(e) => {
                                setMenuOpened(false)
                                e.stopPropagation()
                              }}
                            />
                          )}
                        </ListItemButton>
                        {activeTopic?.id === topic.id && menuOpened && (
                          <List sx={{ width: '100%' }}>
                            {getMenuPoints(topic.id, null).map((category) => (
                              <ListItem
                                key={category.id}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start'
                                }}
                              >
                                <ListItemButton
                                  sx={{ width: '100%' }}
                                  onClick={() => {
                                    setActiveCategory(category.id, category.title)
                                  }}
                                >
                                  <ListItemText primary={category.title} />
                                </ListItemButton>
                                {activeCategory?.id === category.id && (
                                  <List sx={{ width: '100%' }}>
                                    {getMenuPoints(topic.id, category.id).map((subCategory) => (
                                      <ListItem key={subCategory.id}>
                                        <ListItemButton
                                          sx={{ width: '100%' }}
                                          onClick={() => {
                                            setActiveSubCategory(subCategory.id, subCategory.title)
                                          }}
                                        >
                                          <ListItemText primary={subCategory.title} />
                                        </ListItemButton>
                                      </ListItem>
                                    ))}
                                  </List>
                                )}
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </ListItem>
                    ))}
                </List>
              </Drawer>
            )}
          </Box>
          <Box
            sx={{
              display: 'block',
              flexGrow: 1,
              ml: 2
            }}
          />
          <Button
            onClick={() => {
              clearParams()
              window.scrollTo({ top: 0, behavior: 'smooth' })
              navigate('/')
            }}
          >
            <img src={siteLogo} alt='logo' height={isDesktop ? 40 : 30} />
          </Button>
          <Box
            sx={{
              flexGrow: 1,
              ml: 2
            }}
          />
          <Box sx={{ ml: 2, mr: 2 }}>
            <Button
              variant='text'
              onClick={() => navigate('/pytac')}
              endIcon={<SearchOutlined htmlColor='white' />}
            >
              <Typography color='white'>Pytać</Typography>
            </Button>
          </Box>
          {isDesktop && (
            <Box>
              <a href='https://lucija.de' style={{ marginRight: 10 }}>
                <img src={logoLucija} alt='logo' height={40} />
              </a>
              <a href='https://www.sorbischer-schulverein.de/hs' style={{ marginRight: 1 }}>
                <img src={logoSst} alt='logo' height={40} />
              </a>
            </Box>
          )}
        </Toolbar>
        <img
          src={headerImage}
          alt='header_line'
          height={20}
          style={{ objectFit: 'cover', zIndex: 3, position: 'absolute', top: 54 }}
        />
      </DashboardNavbarRoot>

      <Main
        sx={{
          marginTop: '64px',
          paddingBottom: 6
        }}
        open={drawerOpened}
      >
        {content}
      </Main>
      <div style={{ marginLeft: -10 }}>
        <Footer />
      </div>

      <LoadingSpinner isVisible={isFetching > 0} />
    </div>
  )
}

export default App
